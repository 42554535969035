import styled, { css } from 'styled-components'

const Spacer = styled.div`
  display: block;
  margin-bottom: 8rem;
  content: ' ';

  @media (max-width: 580px) {
    margin-bottom: 4rem;
  }

  ${props =>
    props.size === 'small' &&
    css`
      margin-bottom: 4rem;

      @media (max-width: 580px) {
        margin-bottom: 2rem;
      }
    `}
`

export default Spacer
