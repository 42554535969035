import React from 'react'
import { graphql } from 'gatsby'
import { FormattedMessage, useIntl } from 'react-intl'

import Layout from '../components/Layout'
import SEO from '../components/Seo'
import Content from '../components/Content'
import Title from '../components/Title'
import Spacer from '../components/Spacer'
import Competences from '../components/Competences'

const CompetencesPage = ({ data }) => {
  const intl = useIntl()

  const competences = data.allContentfulCompetence.edges.map(edge => {
    const { node } = edge
    return Object.assign(node)
  })

  return (
    <Layout>
      <SEO title={intl.formatMessage({ id: 'competences.title' })} />
      <Content intro>
        <Title top={intl.formatMessage({ id: 'competences.title' })}>
          <FormattedMessage id="competences.headline" />
        </Title>
        <Competences competences={competences} />
        <Spacer size="medium" />
      </Content>
    </Layout>
  )
}

export default CompetencesPage

export const i18n = {
  en: '/competences',
  de: '/taetigkeitsbereiche',
}

export const query = graphql`
  query($i18nCurrentLocale: String!) {
    allContentfulCompetence(
      sort: { fields: title }
      filter: { node_locale: { eq: $i18nCurrentLocale } }
    ) {
      edges {
        node {
          id
          title
          slug
          content {
            json
          }
        }
      }
    }
  }
`
