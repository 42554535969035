import React from 'react'
import styled from 'styled-components'

const Svg = styled.svg`
  display: block;
  width: 34px;
  height: auto;
`

const ArrowRight = () => (
  <Svg viewBox="0 0 34 10" xmlns="http://www.w3.org/2000/svg">
    <g
      transform="rotate(-90 4.5 4.5)"
      stroke="#E63B27"
      fill="none"
      fillRule="evenodd"
    >
      <path d="M4.09 0v33.27" />
      <polyline points="7.81 29.26 4.09 33.27 0.37 29.26" />
    </g>
  </Svg>
)

export default ArrowRight
