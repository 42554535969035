import React, { useContext } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { useSpring, animated } from 'react-spring'

import ArrowRight from './ArrowRight'
import title3Styles from '../styles/title3'
import I18nContext from '../../plugins/gatsby-plugin-i18n/utils/I18nContext'

const Wrapper = styled.div``

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`

const Item = styled.li`
  border-bottom: 1px solid ${props => props.theme.colors.primary};

  &:first-of-type {
    border-top: 1px solid ${props => props.theme.colors.primary};
  }
`

const StyledLink = styled(Link)`
  ${title3Styles}
  display: block;
  padding: 1rem 0;
  text-decoration: none;
  display: flex;
  align-items: center;
`

const Icon = styled(animated.div)`
  display: inline-block;
  position: relative;
  top: 2px;
  margin-left: 1rem;

  @media (max-width: 840px) {
    display: none;
  }
`

const Competence = ({ competence }) => {
  const i18n = useContext(I18nContext)
  const [props, set] = useSpring(() => ({ transform: 'translateX(0rem)' }))

  const pageRoute =
    i18n.currentLocale === 'en' ? '/en/competences' : '/de/taetigkeitsbereiche'

  return (
    <StyledLink
      to={`${pageRoute}/${competence.slug}`}
      onMouseEnter={() => {
        set({ transform: 'translateX(1rem)' })
      }}
      onMouseLeave={() => {
        set({ transform: 'translateX(0rem)' })
      }}
    >
      {competence.title}
      <Icon style={props}>
        <ArrowRight />
      </Icon>
    </StyledLink>
  )
}

const Competences = ({ competences }) => {
  return (
    <Wrapper>
      <List>
        {competences.map(competence => (
          <Item key={competence.id}>
            <Competence competence={competence} />
          </Item>
        ))}
      </List>
    </Wrapper>
  )
}

export default Competences
